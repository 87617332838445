// This module processes nouns to return paradigms.
const { FEM_OIKO_I, MASC_XENO, MASC_XENO_NOM_PL_A } = require('./exceptions');

export enum Gender {
    masculine = "masculine",
    feminine = "feminine",
}

enum NounType {
    oiko = "oiko",
    xeno = "xeno",    
    notDefined = "notDefined",
}

enum Case {
    nominativ = "nominatív",
    genitiv = "genitív",
    dativ = "datív",
    akuzativ = "akuzatív",
    vokativ = "vokatív",
    lokal = "lokál",
    ablativ = "ablativ",
    instrumental = "inštrumentál",
}

type NounResult = {
    obliquusSingular: string, 
    obliquusPlural: string, 
    gender: Gender 
    nounType: NounType
}

type NounParadigm = {
    singular: Record<Case, string>,
    plural: Record<Case, string>
};

function generateObliquus(word: string, gender: Gender): NounResult {
    /**
     * Generate obliquus forms of a given word based on its gender and type.
     */

    const result: NounResult = {
        obliquusSingular: '', 
        obliquusPlural: '',                 
        gender,
        nounType: NounType.notDefined
    }
    
    const isMasXenoException = MASC_XENO.includes(word);
    const isFemOikoIException = FEM_OIKO_I.includes(word);

    // Abstract
    if (/(ben|pen|ipen|iben)$/.test(word)) {
        result.nounType = NounType.oiko;
        result.gender = Gender.masculine;
        result.obliquusSingular = word.slice(0, -2) + word[word.length - 1] + "as";
        result.obliquusPlural = result.obliquusSingular.slice(0, -2) + "en";
    }
    // Xenoclitic masculine
    else if (/(is|as|os|us)$/.test(word) && !isMasXenoException) {
        result.obliquusPlural = word.slice(0, -2) + "en";
        result.nounType = NounType.xeno;
        gender = Gender.masculine;
    }
    // Xenoclitic feminine
    else if (word.endsWith("a")) {
        result.obliquusPlural = word.slice(0, -1) + "en";
        result.nounType = NounType.xeno;
        gender = Gender.feminine;
    }
    // Oikoclitic feminine -i suffix
    else if (word.endsWith("i") && !isFemOikoIException) {
        result.obliquusSingular = word.slice(0, -1) + "a";
        result.obliquusPlural = word.slice(0, -1) + "en";
        result.nounType = NounType.oiko;
        gender = Gender.feminine;
    }
    // Oikoclitic Masculine -o suffix
    else if (word.endsWith("o") || isFemOikoIException) {
        result.obliquusSingular = word.slice(0, -1) + "es";
        result.obliquusPlural = word.slice(0, -1) + "en";
        result.nounType = NounType.oiko;
        gender = Gender.masculine;
    }
    // Oikoclitic Masculine -Ø suffix
    else if (gender === Gender.masculine || isMasXenoException) {
        result.obliquusSingular = word + "es";
        result.obliquusPlural = word + "en";
        result.nounType = NounType.oiko;
        gender = Gender.masculine;
    }
    // Oikoclitic feminine -Ø suffix
    else if (gender === Gender.feminine) {
        result.obliquusSingular = word + "a";
        result.obliquusPlural = word + "en";
        result.nounType = NounType.oiko;
        result.gender = Gender.feminine;
    }

    return result;
}

export function generateNounParadigms(word: string, gender: Gender, animacy: boolean): NounParadigm {
    /**
     * Generate noun paradigms (singular and plural forms) based on the word's properties.
     */

    // Define suffixes for each grammatical case
    const suffixes: Record<Case, [string, string]> = {
        [Case.nominativ]: ["", ""],
        [Case.genitiv]: ["kero", "gero"],
        [Case.dativ]: ["ke", "ge"],
        [Case.akuzativ]: gender === Gender.masculine ? ["es", "a"] : ["a", "en"],
        [Case.vokativ]: gender === Gender.masculine ? ["eja", "ije"] : ["ije", "ale"],
        [Case.lokal]: ["te", "de"],
        [Case.ablativ]: ["tar", "dar"],
        [Case.instrumental]: ["ha", "ca"],
    };

    // Initialize paradigms
    const paradigms: NounParadigm = {
        singular: {} as Record<Case, string>,
        plural: {} as Record<Case, string>,
    };

    // Generate obliquus forms using the helper function
    const { obliquusSingular, obliquusPlural, nounType } = generateObliquus(word, gender);

    // Generate paradigms for each grammatical case
    for (const [caseName, [suffixSingular, suffixPlural]] of Object.entries(suffixes) as [Case, [string, string]][]) {
        const result = applyCaseRules(
            word,
            obliquusSingular,
            obliquusPlural,
            gender,
            nounType,
            animacy,
            caseName,
            suffixSingular,
            suffixPlural
        );        

        paradigms.singular[caseName] = result[0];
        paradigms.plural[caseName] = result[1];
    }

    return paradigms;
}

function applyCaseRules(
    word: string,
    obliquusSingular: string,
    obliquusPlural: string,
    gender: Gender,
    nounType: NounType,
    animacy: boolean,
    grammaticalCase: Case,
    suffixSingular: string,
    suffixPlural: string
): [string, string] {
    /**
     * Applies specific rules for each grammatical case to generate singular and plural forms.
     */

    switch (grammaticalCase) {
        case Case.nominativ:
            return nominativeCase(word, gender, nounType, obliquusPlural);

        case Case.akuzativ:
            return accusativeCase(
                word,
                obliquusSingular,
                obliquusPlural,
                suffixSingular,
                suffixPlural,
                gender,
                animacy,
                nounType
            );

        case Case.vokativ:
            return vocativeCase(
                word,
                obliquusSingular,
                suffixSingular,
                obliquusPlural,
                suffixPlural,
                gender,
                animacy,
                nounType
            );

        case Case.instrumental:
            return instrumentalCase(
                word,
                obliquusSingular,
                obliquusPlural,
                gender,
                nounType,
                suffixSingular,
                suffixPlural
            );

        default:
            return [
                obliquusSingular + suffixSingular,
                obliquusPlural + suffixPlural,
            ];
    }
}

function nominativeCase(
    word: string,
    gender: Gender,
    nounType: NounType,
    obliquusPlural: string
): [string, string] {
    /**
     * Generates nominative case forms for singular and plural based on word properties.
     */

    if (nounType === NounType.xeno && gender === Gender.masculine) {
        return [
            word,
            word.slice(0, -2) + (MASC_XENO_NOM_PL_A.includes(word) ? "a" : "i"),
        ];
    } else if (nounType === NounType.oiko && gender === Gender.masculine) {
        if (word.endsWith("o") || FEM_OIKO_I.includes(word)) {
            return [
                word,
                word.slice(0, -1) + (word.endsWith("o") ? "e" : "a"),
            ];
        } else {
            return [word, word + "a"];
        }
    } else if (nounType === NounType.xeno && gender === Gender.feminine) {
        return [word, word.slice(0, -1) + "i"];
    } else if (nounType === NounType.oiko && gender === Gender.feminine) {
        return word.endsWith("i")
            ? [word, word.slice(0, -1) + "a"]
            : [word, word + "a"];
    } else {
        return [word, obliquusPlural];
    }
}

function accusativeCase(
    word: string,
    obliquusSingular: string,
    obliquusPlural: string,
    suffixSingular: string,
    suffixPlural: string,
    gender: Gender,
    animacy: boolean,
    nounType: NounType
): [string, string] {
    /**
     * Generates accusative case forms based on word properties, gender, animacy, and noun type.
     */

    if (nounType === NounType.xeno) {
        return [word, obliquusPlural];
    } else if (nounType === NounType.oiko && gender === Gender.masculine) {
        if (animacy) {
            return [obliquusSingular, obliquusPlural];
        }
        if (word.endsWith("o") || FEM_OIKO_I.includes(word)) {
            return [
                word,
                word.slice(0, -1) + (word.endsWith("o") ? "e" : "a"),
            ];
        }
        return [word, word + "a"];
    } else if (nounType === NounType.oiko && gender === Gender.feminine) {
        return [
            animacy ? obliquusSingular : word,
            obliquusPlural,
        ];
    } else {
        return [
            obliquusSingular + suffixSingular,
            obliquusPlural + suffixPlural,
        ];
    }
}

function vocativeCase(
    word: string,
    obliquusSingular: string,
    suffixSingular: string,
    obliquusPlural: string,
    suffixPlural: string,
    gender: Gender,
    animacy: boolean,
    nounType: NounType
): [string, string] {
    /**
     * Generates vocative case forms based on word properties, gender, animacy, and noun type.
     */

    if (nounType === NounType.xeno && gender === Gender.masculine) {
        if (animacy) {
            return [word.slice(0, -2) + "ona", word.slice(0, -2) + "ale"];
        } else {
            return [word, obliquusPlural];
        }
    } else if (nounType === NounType.oiko && gender === Gender.masculine) {
        if (animacy) {
            return [
                word.endsWith("o")
                    ? word.slice(0, -1) + "eja"
                    : word + "eja",
                word.endsWith("o")
                    ? word.slice(0, -1) + "ale"
                    : word + "ale",
            ];
        }
        if (word.endsWith("o")) {
            return [word, word.slice(0, -1) + "e"];
        } else if (FEM_OIKO_I.includes(word)) {
            return [word, word.slice(0, -1) + "a"];
        } else {
            return [word, word + "a"];
        }
    } else if (nounType === NounType.xeno && gender === Gender.feminine) {
        if (animacy) {
            return [word, word.slice(0, -1) + "i"];
        } else {
            return [word, obliquusPlural];
        }
    } else if (nounType === NounType.oiko && gender === Gender.feminine) {
        if (animacy) {
            return [word, obliquusPlural];
        }
        if (word.endsWith("i")) {
            return [
                word.slice(0, -1) + "ije",
                word.slice(0, -1) + "ale",
            ];
        } else {
            return [word + "ije", word + "ale"];
        }
    } else {
        return [
            obliquusSingular + suffixSingular,
            obliquusPlural + suffixPlural,
        ];
    }
}

function instrumentalCase(
    word: string,
    obliquusSingular: string,
    obliquusPlural: string,
    gender: Gender,
    nounType: NounType,
    suffixSingular: string,
    suffixPlural: string
): [string, string] {
    /**
     * Generates instrumental case forms based on word properties, gender, and noun type.
     */

    if (nounType === NounType.xeno && gender === Gender.masculine) {
        return [word.slice(0, -1) + "ha", obliquusPlural + suffixPlural];
    } else if (nounType === NounType.oiko && gender === Gender.masculine) {
        return [obliquusSingular.slice(0, -1) + suffixSingular, obliquusPlural + suffixPlural];
    } else {
        return [obliquusSingular + suffixSingular, obliquusPlural + suffixPlural];
    }
}

// Calling function need animacy (true | false) => third parameter
// const testCase = generateNounParadigms("uraviben", Gender.masculine, true)
// console.log(JSON.stringify(testCase))
