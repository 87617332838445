export const lemmaSigns = `
abeceda
abecedakero
abecedno
abo
absencija
absin
absinel
absiňalo
absiňarel
absiňiko
absolutno
absolutnones
absolventos
absolvinel
abstinentos
abstraktno
absuno
absurdno
ačhado
ačhaduno
ačhaďipen
ačhaďol
ačhaľiben
ačhavel
ačhavel pes
ačhavel peske
ačhavel pes paš (varekaste)
ačhaviben
ačhavibnaskero
ačhavindos
ačhavkerel
ačhavkeriben
ačhel
ačhel avri
ačhel jakhendar
ačhel opre
ačhel palal
ačhel paš
ačhel pes
ačhel peske
ačhel pre
ačhel pre čhib
ačhel pre peskero
ačhel pro asaben
ačhel pro purano ciral
ačhel romeha
ačhel romňaha
ačhel vaš
ačhiben
ačhilo paňi
ačhkerel
ačhol
ada
adaberš
adaberš
adaberšeskero
adaďives
adaďives
adaďiveseskero
adaďivesutno
adaj
adaptacija
adaptisarel
adaraťakero
adarig
adarigutno
adarigutno
adathaneskero
adathaneskero
adathar
adatharuno
adatharuno
adava
adebor
adebororo
adeci
adej
adekvatno
adi
adig
adijader
adinel
adirat
adirat
adiraťakero
administrativa
adopcija
adoptinel
adošagos
adrenalinos
adresa
adventos
advokatkiňa
advokatos
adžgar
afektinel
afektos
afektovano
africko
Afričankiňa
Afričanos
Afrika
agatos
agenda
agentkiňa
agentos
agentura
agitacija
agocis
agor
agora
agoral
agoralo
agoraluno
agoralutno
agorarel
agore
agorestar
agorisaľol
agorisarel
agororo
agoruno
agorutno
agos
agresija
agrikultura
aha
ahoj
achaľardes
achaľardo
achaľardo
achaľarel
achaľipen
achaľipnaskero
achaľol
achaľol avri
achaľol pes
achaľol pes andre
achaľutno
achaľuvipen
aja
ajarat
ajarat
ajci
ajcici
ajcigori
ajcijori
ajci so
ajsidžeňi
ajsijoro
ajso
ajso
ajsodženo
ajsoro
ajso sar the
ak
akademicko
akademija
akademikos
akaj
akajcici
akajso
akalaraťakero
akaledženeskero
akaleskero
akana
akanak
akanake
akanakero
akanakes
akanastar
akanutnes
akanutno
akaňik
akarig
aka svetos
akava
akavka
akceptinel
akcija
akcijonaris
akčno
ake
aki
aki
akirat
akirat
akiraťakero
akor
akorestar
akor sar
akor the akor
akorutno
akorutno
aktivistas
aktivita
aktivizinel
aktivno
aktivnones
aktos
aktualizinel
aktualno
akutno
akuzativno
akuzativos
akvarelis
akvareliskero
akhaľiben
akhar
akharel
akharel avri
akharel pes
akhariben
akhor
akhorin
akhoruno
ala
alamoňiko
alamoňis
alaraťakero
alatos
ale
aleberšeskero
aleforoskero
alegaveskero
alethaneskero
alethemeskero
alfabeta
alimentaris
alimenti
alkodinel
alkodišagos
alkoholis
aľe
amal
amalikanes 
amalin
amalipen
amaro
amaro (manuš)
ambora džuvľi
ambrela
ambreľaris
ambrol
ambroľin
amen
Amerika
amoňis
analiza
ancugos
andal
andal
andaruno
andaruno
andral
andraluno
andraluno
andre
andre
andremardo
andrephandlo
andrephandlo
andrethodo
andrethoviben
androda
andruno
andruno
anďelos
anel
anel andre ladž
anel andro diliňipen
anel avri
anel avri pro asaben
anel opre
angaľate
angaľi
angar
angara
angarengero
angaruno
angelos
angina
anglal
anglal
anglalal
anglaľarel
anglatuno
angle
angle
angleder
angledikhiben
anglelav
anglephendo
anglephenďipen
angle savorengere jakha
anglesikhaviben
anglešuňiben
angletradel
angloda
anglodilos
anglodilos
angluno
angluno
anglutnes
anglutno
angľarel
angľiciko
Angľičanos
Angľija
angľipen
angomis
angrusťalo
angrusťi
angušt
angušto
anguštora
angutnes
angutno
antalkos
antlagerinel
antlageris
aňi
aňi
aňjelis
aňjelos
aplauzis
apriľis
aproha
aps
apsin
apsin
arado tele
araďipen
araďol
arakhavel (avri)
arakhaviben
arakhel
arakhel avri
arakhelkerel
arakhel pes
arakhel pes pre sera
arakherkerel avri
arakhiben
arakhipen
arakhľipen
arakhľol
aralo
aravel (tele)
arčič
arde
arde oďa
arderer
ardza
ardzavimen
ardzavo
areskero
argumentos
architektos
architektura
archivos
aritmetika
armada
armin
arminakero
aro
arterija
artiklos
aruno
arza
asaben
asabena
asabno
asal
asal andre
asal pre
asaľarel
asando
asanďol
asanďutno
asanďutno
asangutno
asangutno
asavel
asavel avri
asaviben
asavindo(ne)s
asavkerel
asavkeriben
asentirka
asistentka
asocijacija
aspektos
aspin
astarďol
astarel
astarel avri
astarel opre
astariben
ašardo
ašarďutno
ašarďutno
ašarel
ašarel pes
ašargutno
ašargutno
ašariben
ašaribnaskero (manuš)
ašarkerdo
ašarkerel
ašarkeriben
aščaloda
ašundo
ašunďipen
atomiko
atomos
atoska
atresis
athar
athe
augustos
Australija
autobusis
automatos
avel
avel andre
avel avri
avel opre
avel pale
avel pre goďi
avel tele
aver
aver
aver berš
averipen
averval
avervar
avgin
avgiňaľi
aviben
avindo
avka
avka sar
avke(s)
avluno
avračhibakero
avradumakero
avral
avral aver
avraluno
avreder
avreskero
avrethemeskero
avrethemeskero
avrether
avretheruno
avri
avriakhardo
avriakhariben
avribanďardo
avribarardo
avridemavipen
avridemipen
avridino
avridžando
avrichudkeriben
avrikidno
avrikidno
avrikheľiben
avriľikeriben
avrimardo
avrimarďipen
avrimerkerdo
avrimurdaripen
avrimurdarkeriben
avriprastado
avriprastavel
avripheniben
avriphučkeriben
avrirun(d)o
avrisikhado
avrisikhado ľil
avrisivkerďipen
avrisivkeriben
avrišuťilo
avritado
avritradlo
avritradlo
avritradno
avritradno
avrizorardo
Azija
až
až igen
baba
babica
babilonlos
babka
babrinel
babrinel andre
babrinel pes
babul'as
bacilos
baciľis
bačas
bačinel
bačis
badogos
baďiľa
baginel
bagova
bagovjaris
bagovos
baguľa
bacharis
bacht
bachtales
bachtalo
bachtaloro
bachtaľipen
bacht bibacht
bachťariben
bachťipen
baj
bajašno
bajcinel
bajcinkerel
bajinel
bajoneta
bajonetos
bajos
bajta
bajusato
bajusi
bakombradi
bakrano
bakri
bakrikano
bakro
bakroro
baksa
baksica
bakuľa
bal
balado
balaginel le mulenca
balamuta
balamutinel
balanda
balano
balaňi
balaňori
balevas
balevaseskero
balevasoro
balikos
balkona
balkonos
balo
balogno
balona
balonos
baloro
balos
balvaj
balvajuno
balval
balvazis
balviris
balvirka
baľi
baľičano
baľiči
baľičo
baľipen
baľogaris
baľogno
bananos
banda
band'arkerdo
bandaska
bandera
bandistas
bandurčeňikos
bandurka
bandurkengero
banďardo
banďardo avri
banďarďipen
banďarel
banďarel pes (tele)
banďarkerel
banďarkerel pes
banďilo
banďol
banďol avri
banďol tele
banďuno
bangečangengero
bangejakhengero
bangejileskero
bangepindrengero
banges
bango
bankos
bantinel
baňa
baňikos
baňoga
baňogaris
baňogno
bar
bar
bara
baracka
baračhibakero
baragoďakero
barakero
barakero
barakos
baralo
barapaťivakero
barardo avri
bararel
bararel avri
bararel pes
bararel pes andre
baratkiňa
baratkozinel pes
baratos
baratšagos
barberis
barberňa
barebalengero
bareberšengero
barečangengero
bareder
bareder
baregojengero
baregojengero
barengero
barepindrengero
barepindrengero
bares
bareskero
barešereskero
barešereskero
bari balvaj
baridara
barikanes
barikano
barikaňardo
barikaňarel
barikaňarel pes
barikaňipen
baripen
baripnaha
Bari rat
bari rat
bari roj
Bari Šaroša
baro
baro
baro ďives
baro jevend
barol
barometros
baronos
baruno
barvalo
barvalo
barvaľipen
bašaďol
bašavel
bašaviben
bašel
bašno
bašol
bavinel
bavinel pes
bečelinel
bečka
belavo
beng
beng
bengales
bengeskero
bengiňalo
bengipen
bengoro
berš
-beršengero
bertena
bešel
bešel tele
bešľarel
betonos
betonovo
bi
bibacht
bibacht
bibachtalo
bibachtalo (manuš)
bibachtaľipen
bibachtaľišagos
bibalengero
bibi
Biboldo
biboldo
Bibolďi
bicigľa
bicigľaris
bicigľos
bičhado
bičhavel
bičhavengero
bida
bijanel
bijav
bijaveskero
bije
bikenel
bikenkerel
bilondo
biľa
biľardo
biľarel
biľol
bimeribnaskero
binos
birinel
bisterdo
bisterel
biš
bitangos
bitkaris
bitungos
bizanťiko
biznisos
bizo
bizorakero
blaka
blatňiki
bloka
bludos
bľachuno
bo
bobaľka
bobkos
boganča
bokeľi
boksos
bokh
bokhalo
bokhaľol
bokheľi
bola
bolavel
boldo
bolel
bomba
bonďaľol
boňa
bori
borotva
borovki
boršos
bosorakos
bosorinel
bosorka
bota
bov
bozuľa
brača
bračošis
braďi
brahuncos
brahuňiskos
brajinel
brantos
branzľeta
bratňakos
bratrancos
brehos
breza
breziko
briga
brigaha
brinkinel
brinkos
brišind
brišindeskero
brišinduno
britva
britvinel pes
brotvana
brozuľa
brugova
bubna
bubnos
bubreška
buda
budaris
budinel
budinel avri
budošinel
budžalo
budžando
budžandoha
budžanďi
budžanďipen
budžanďipnaha
budžanglo
bugeľaris
bugošis
bugova
buchles
buchlo
buchľardo
buchľarel
buchľarel pes
buchľaripen
buchľi goj
buchľipen
buchľol
buchta
buchvalcos
bujakos
bujdošinel
bujtošinel
bujtošis
buke
bukengero
buketa
buklomatos
bukľa
bukľato
buko
bukos
buksa
bul
buľa
buľkinel
bunčos
buntinel
buntinel opre
buntišagos
buntošinel
bur
bura
buralo
buravo
burgiňa
burgova
burimen
burina
burka
burkinel avri
burkos
burňik
burňuvos
burutuno
but
buter
buterval
butoris
butvar
buťa
buťakero
buťakero
buťarel
buťi
buzno
buzňi
buzuľa
bužňa
cacha
cajgeris
caklendar
caklos
cakluno
cakos
calkom
calo
capos
cardel
cardkerel
casnikos
cegera
cehelňa
cehla
ceholňa
celo
celos
ceľaciko
ceľatos
ceľno gurumňi 
cemeľis
cementos
cemeňis
cena
centimetros
centos
cerha
cerhakero
cerharis
cerňi
cerpindo
cerpinel
cerpisaľol
ceruska
cesnakos
cesnakoskero
cibera
cicic
cicinel
cicinel avri
cicinel pes
cicirdel
cicka
cickimen haluški
cickinel
cickos
cifrimen avri
cifrinel pes
ciganka
cigarekľa
cigaretľa
cicha
cichľarel
cicho
cicho
cicho
cichones
ciknepaňeskero
cikno
cikno
cikno angušt
ciknorendar
ciknorestar
ciknoro
ciknoro
cikno šero
ciknovara
ciknovaratar
ciknovarba
ciknovarbaste
ciknovarbatar
ciknovarestar
cikňarel
cikňarel pes
cikňarel pes
cikňi bugova
cikňi buťi
cikňol
cikňoratar
cilo
ciloro
cimbalma
cimbalmošis
cimbľa
cimentos
cimermanos
cimprlitkos
cimra
cinado
cinado
cinavel
cinaviben
cinavkerel
cindo
cindo
cinďardo
cinďarel
cinďipen
cinďol
cinďol
cinel
cinel avri 
cinel pes avri
cinkerel
cinkeriben
cinos
cintirija
cintiris
ciňiben
cipa
cirach
ciral
cirdel andre
cirdel avri
cirdel opre
cirdel pes
cirdel pes andre
cirdel pes avri
cirdel tele
cirkusis
cirňi
cisaris
citrona
citronos
civilizacija
civiľis
cmaros
cocha
colňica
cudzo
cugos
cukraris
cukrikos
cukros
cvičinel
cvikla
cvikľa
cvirkinel
ča
ča
ča
ča ajso
ča avka
čačikano
čačipen
čačo
čačo
čajos
čak
čalavel
čalavel pes/peha
čalaviben
čalo
čaľarel
čaľarel pes
čaľol
čang
čar
čara
čaralo
čardašis
čarel
čaro
čaruno
časos
čata
Čechi
čechika
Čechiňa
Čechos
čekat
čeňa
čepo
čepos
čeranka
čercheň
čerchinel
čerkinel
čertos
či
či..., či...
čik
čika
čikalo
čikaľarel pes
čikaľipen
čiken
čikenalo
čikľardo
čikuno
čikh
čineder
činederipen
čino
činokaj
činokana
činoko
činoro
činosavo
činoso
čipetki
čipka
čira
čiriklano
čiriklo
čirikloro
čirikľi
čirka
čirla
čirlatunes
čirlatuno
čirlatuno
čirlatuňipen
čirleder
čiro
čiros
čislos
čisto
čisťinka
čišlo
čišľol
čit!
čitalo
čitel
čiten!
čitiben
čitkinel
čitľarel
čitľariben
čitľol
čkajinel
čobaňi
čokanos
čokolada
čombokos
čor
čor
čoragoďakero
čorachano
čorachaňi
čorachaňiben
čoral
čoraľiben
čorel
čori
čorikano
čoripen
čoro
čoro
čororo
čučo
čudakos
čudaľinel pes
čuchinel
čulo
čuľal
čumidel
čuno
čušagos
čhama
čhave
čhavorikano
čhavoro
čhib
čhibalo
čhil
čhiľav
Čhindo
Čhinďi
čhinďol
čhinel
čhinel paťiv
čhingerdo
čhingerel
čhivel
čhivel o karti
čhivel pes
čhivel pes pal
čhivkerel
čhon
Čhon
čhorďol
čhorel
čhorvalo
čhungar
čhungard
čhungardel
čhungarel
čhuri
dab
dad
dachos
dand
danderel
dar
daral
daranďipen
daranďol
daravel
dare-
darekaj
darekana
dareko
darekhaj
dareso
darjav
darunkos
degeša
degešiko
degešis
del avri o jakha
del brišind
del buľe
del čačipen
del čikh
del čikha
del čhuri
del dab
del duma
del dumi
del goďi
del jiv
del kejčen
del kham
del lav
del paťiv
del pes
del smirom
del šol
del šoľa
demado
demado
demaďol
demavel
demel avri
demel (pes)
denašel
denašiben
derekos
derjav
deska
destos
deš
dešujekh
devleskero
Devloro
devľikano
dičhol
dikh ča
dikhel
dilino
dilino
dilos
diľino
diľino
diľiňarel
diľiňipen
dino
diňa
diz
dodikhel
dodikhel
dodžal
dodživel pes
dohanos
dochudel
dochudel pes
dochuťel
dojekh
dokerel pes
doktoris
domukhel
domukhel peske
dori
doš
došel
došunel
došunel pes
dothovel pes
doužarel
dovakerel
dovakerel pes
dovoľenka
drab
drabarel
drakos
drom
drota
drotos
dručkos
drukos
dubkos
dudum
duchos
duj
dujejilengero
dujevoďengero
dujto
dujto phen
dujto phral
dukh
dukhado
dukhal
duma
duminel
dumo
dupľarel
dur
dural
dureder
durkinel
dušman
duvar
dvojinel
dvora
ďiľavel
ďinďardo
ďinďarel
ďives
ďivesaľol
dzar
dzaralo
dzeka
dzivo
dzvederis
džal andre
džal opre
džamutro
džanel
dženo
-dženo
džeňi
dži
džido
džiďarel
džikim
dživel
dživipen
džombra
džov
džung
džungales
džungalo
džungipen
džungľol
džungľol
džuv
džuvľi
e
e daj o dad
efta
egzistinel
ehe
el
elektricko
elektrika
elšino
eľektrika
eňa
erďavo
eskos
ešebno
Europa
ezeros
faďimen
faďinel
fajta
fala
falošno
fameľija
famiľija
farba
feder
feder
fenek
figinel
fiškaľis
fizolos
fizoľa
fizoľis
fizuľa
foga
forkija
foros
forutno
frajeris
frajerka
fraza
frima
ftorkos
furt
gad
gada
gadži
gadžikanes
gadžikano
gadžo
garadičos
garudo
garuvel
garuvel pes
garuvipen
gav
gaveskero
gavutno
gazdas
gendalos
genďi
genel
gerekos
geri
gero
gero
giľavel
giľavipen
giľi
giľipen
giľutňi
ginel
ginel
gitara
goďaver
goďi
goj
gombička
gomboška
gondoľinel
gondoľinel
gono
goreder
goreder
gori
gorila
goro
goro
grasňi
grast
grati
gravčinel
grofiko
grofkiňa
grofos
gruľa
gulo
guľarel
guľi daj
guľi phen
gurumňa
gurumňalo
gurumňi
guruv
guruvano
guruvňi
ha
hadinel
haďos
hakaj
hakajuno
halgatos
halgatovos
halka
haluški
hamišagoha
hamišagos
hamižno
handľinel
hangos
harangos
hartinel absin
has
hat
hazdel (opre/upre)
hazdel pes
he
heďos
hel
hem
hercos
herečka
het
hetvin
hi
hibaj
hijaba
hijaba kaj
hiros
historija
hjaba
hjaba kaj
hoc-
hockaj
hockana
hocko
hocso
hoj
hokejka
holubki
hordinel
horučka
hrabľi
hrobos
hura
chaben
chal
chal bokh
chal dukh
chal choľi
chal pes
chanďi
chanel
chanľi
chaňig
char
char
charakteris
charčinel
charno
charo
charťas
charťiko
chas
chasňarel
chasňarel avri
chasňisaľol
chemicko
chev
chevalo
chirchil
choc
choc-
choč
choč-
choča
chochado
chochado
chochavel
cholov
choľamen
choľarel
choľarel pes
choľasaľol
choľi
choveľi
chudel pes
chudkerel
chulajinel
chulaňi
chumer
chundrul
churdo
chvala
ič
idž
igen
iľinel
iľinel
imar
Indija
indraľol
indraľori
inepos
ispidel
izdral
izdral
izdrano
izdrano
izdraňi
jag
jagalo
jahoda
jaj
jajgatinel
jakh
jalo
(jandreskero) šargo
jandro
jara
jareskero
jarmin
jarminakero
jaro
jaros
jastrabos
jejsos
jekh
jekh
jekh
jekh
jekhbareder
jekhbuter
jekhetane
jekhetaňiben
jekhfeder
jekhfeder
jekhgoreder
jekhgoreder
-jekhto
jekhvar
jekhvareste
jepaš
jevend
jezulankos
jilo
jiv
jiveskeri babka
joj
joj
jon
jov
kačkakero
kada
kadaj
kadi
kachňi
kaj
kaj
kaja
kajci
kajča
kajkamel
kajso
kaj te
kaj... te
kaj the... te
kak
kakos
kala
kalapa
kale
kalo
(kalo) buko
kalo buko
kalo segno
kaľarel
kaľi segno
kaľol
kamaratos
-kamel
kamiben
kampel
kan
kana
kanakamel
kanastar
kandrato
kandro
kangľi
kaňaris
kapčinel
kapkica
kapura
Karačoňa
karačoňakero
karčma
kašt
kaštuno
kašťiľis
kašukarel
kašuko
katinel
kaveja
kavejovo
kavka
ke
kebro
keci
kerades
kerado
kerado
keraďipen
kerďol
kerel
kerel balamuta
kerel buťi
kerel cirkusis
kerel dukh
kerel ladž
kerel pes
kerel pheras
kerel phujes
kerestos
keriben
kidel
kija
kijaraťate
kijaraťi
kije
kikidel
kikimen haluški
kiko
kilometros
kilos
kiľavo
kim
kinos
kipos
kiraľis
kirko
kirlo
kirmo
kirno
kirňol
kirvi
kirvipen
kirvo
klarinetos
klatos
kleja
klobasa
kľončka
kľučaris
ko
kockaľiďi
kockarigi
koča
kočak
kočija
koda
kodi
kodoj
koja
kokal
kokamel
kola
kole
koloňija
koľibica
koľin
kominos
kompiris
koňaris
korarel
korčma
koreňis
korkoro
kornazis
koro
koruna
košaris
košel
košel pes
košiben
koškerel
koter
kotor
kovinca
kovlo
kovľol
kozaris
kožuchos
kraľis
kraľos
kraľovna
kramľa
kresno dad
kresno daj
kresňatkos
krešňatkos
križma
križmos
krokos
krumpľa
krušinkica
kšeftaris
kuckuľigi
kuč
kuči
kuchňa
kurkeskero
kurko
kušťik
kutos
kuvikos
kužum
kvitkos
khabňi
kham
khamľarel pes (avri)
khamľol
khand
khandel
khangeri
khatar
khatar
khelel
khelel avri
kher
kheral
khere
khil
khiľav
khiľavin
khoč
khoro
khosel
khosno
khul
khuro
khuvel
labarel
labol
labuno
lačharel
lačhejileskero
lačhes
lačhiben
lačhipen
lačho
ladž
ladžal (pes)
lakeripen
lakero
lalo
lancos
lanďarel (pes)
lanďol
langalo
langavel
lango
lata
lav
lavka
lavuta
lavutaris
lazaris
lekvaris
lel kejčen
lel palal
lel tele
lemešis
len
lengero
leňivo
leperel
leskeripen
leskero
linaj
lokes
lokiben
loko
lolo
lon
londo
londuno
lopata
lopta
loš
lovina
lubňi
luftos
lukestos
luluďi
ľegos
ľetno
ľidžal
ľikerel
ľikerel avri
ľikerel pes
ľikh
ľil
ľinaj
ľindraľol
ľitra
ľitros
ľivinel
ľubňi
ma
macocha
mačanka
mačka
mačkica
mačhakero
mačhengero
mačheskero
mačhi
mačhi
mačhľi
mačho
mačhuno
madra
macharinel
macharis
maj
maj furt
maj na
majstros
makar-
makarkaj
makarkana
makarko
makarsavo
makarso
makos
makoskero
makhel
makhel andre
makhel avri
makhelkerel
makhel pes andre
makhiben
makhlo
makhlo
makhľardo
makhľarel
makhľiben
makhľipen
makhľol
malmos
malo-
malokaj
malokana
maloko
malosavo
maloso
maľa
maľica
maľiko
maľimen
maľina
maľinel
maľovačka
mami
mamo
mangavel
mangavipen
mangavkerel
mangel
mangel avri
mangel pes
mangipen
manuš
manušano
manušiben
manušibnaskero
manušikano
manušňi
mardo
mardo avri
marďol
marel
marel
marel andre
marel avri
marel katinel
marel murdarel
marel opre
marel peha
marel pes
marel phagerel
mariben
marikľi
maro
mas
mašina
maškar
maškaral
maškarutno
mašľa
maštalňa
mato
maťarel
maťol
maťuvkerel
me
medos
medveďis
megľisaľol
mek
mek sa
melalo
mel'inel
melonos
meľarel
meľarel pes andre
men
mengľisaľol
mentinel avri
merel
merel
meriben
metľa
metros
mi
milijonos
mindig
minďar
minuta
mirikle
miriklo
miro
mišinel
mišos
mištes
mišto
mlaka
mľinkos
močkošis
modelka
moderno
modľinel pes
mochto
mol
mol
momeľi
morči
morel
morel pes
moros
mosaľol
mosarel
mosarel pes
mos(i) te
motoris
mozi
može
mravcos
mrkva
mucha
muchľi
muj
mujal
mukel
mukhel
mukhel pes
mukhel pes
mulano
mulaňi bar
mulatinel
mulo
mulo
murdaľol
murdarel
murdarel andre
murna
murš
muršol
musaj
musaľol
musarel
musi
mus te
mušinel
na
na
nadočirla
nadur
naj
nakh
nalačhes
nalačhipen
nalačho
namištes
namišto
nanďarel (pes)
nanďol
nane
nango
narado
narokom
nasig
nasvalo
nasvaľipen
nasvaľol
našavel
našľol
našťi
nav
nažužipen
nekbuter
nekfeder
nekfeder
nena
nervi
nevipen
nevo
nevo čhon
nipos
ništ
ňebos
ňebošťičko
ňebošťičko
ňemcika
ňemciko
ňemogavo
ňi-
ňič
ňigda
ňikaj
ňikana
ňiko
ňikhaj
ňilaj
ňisar
ňisavo
o
občarkerel
občarkerel pes
obdžal
obdživel
obchudel
oblaka
obnoha
oča
očki
oda
odarig
odarigutno
odathar
odatharuno
odčhivel pes
odej
odi
odija
odijader
odmukhel
odoj
odphenel
oďa
ogurka
ochto
oj
oja
oka
oki
okla
okle
okresis
okruhlo
ola
ole
oltaris
omukhel
on
opalka
opica
opral
opral
opre
opre
oprekidlo
ora
ov
paca
pacerki
pačinel pes
pačisaľol
pados
pachota
pal
palal
pale
palodilos
paltajsaskero
paltajsaste
paľenka
paľidžeskero
paľidžeste
paľikerel
paľis
pametinel
pandž
paňi
paparuga
papin
papiňalo
papros
papus
para
para
parajsovin
paramisaris
paramisi
paraščuvin
parašťovin
parinel
parne buke
parno
parno
parno
parňarel
parňol
parňol avri
parunel (andre)
parušagos
paš
pašal
pašal
paše
pašes
pašľol
patavo
Patraďi
patraďiko
paťaben
paťal
paťiv
paťivalo
pekel
pekiben
peko
penda lačhe
pendech
pendechuno
pengero
per
perdal
perel
perel tele
perňica
peršes
peršo
perumos
pervalo
pes
peskero
pešones
petalos
petalunel
peťol
pijel
pijiben
pindral
pindrango
(pindreskeri) phabaj
pindro
pipa
pirano
piraňi
piri
pisinel
piskinel
pišot
pitvora
pľetki
poharis
pochtan
pojekh
pokerdo
pokerel
polokes
polokores
Poľčiko
Poľsko
pomňikos
pomožinel
pondzelkos
popka
popkica
por
pora
poravel o jakha
pori
posledno
poši
poštaris
poťinel
prahos
prajtengero
prastal
pratinel
pre
preačhel
prečhinel
predsedas
predžal
predživel
prefarbinel
prekal
prethovel
previsarel
prikerel
priklados
primašis
princezna
princos
prindžarel
pripravinel
prišťi
prosto
prvo
pšara
pšaris
pučimen
pučinel
puľaris
puľka
pupčemeris
purano
purum
phabaj
phabaľin
phaďol
phagel
phagerdo
phagerel
phak
phandel (andre)
phandlo
phandlo
phar
pharavel
phares
phari
pharipen
pharo
pharol
phen
phenel
pheniben
pheras
pherasuno
pherdo
pherdo
pherdo čhon
pherďarel
pherďol
pherel
pherno
phiko
phiravel
phirel
phirel avri
phiriben
phivlo
phivľi
phosavel
phral
phraľipen
phučel
phučkerdo
phuj
phuj
phujes
phundrado
phundravel
phundrol
phurd
phurd
phurikano
phuro
phuro
phurol
phus
phuterdo
phuterel
phuv
radijos
radišagos
rado
rachinel
rajbinel
rajeskero
rakinel
raklo
rakľi
raňi
raňi
raňig
raskero
ratinel
raťaha
raťakero
raťi
raťol
rendešno
renta
repaňa
repaňis
rešľol
rešťol
ričh
ričhňi
rikono
rikono
rikoňaris
rikoňi
riňin
roj
rokľa
Rom
rom
romanes
romano
Romňi
romňi
romňori
romoro
rovel
rovľi
rozčhivkerdo
rozčhivkerel
rozdžal
rozdžal pes
rozkerel
rozvalkinel
ruginel
rukono
rukono
rukoňaris
rukoňi
runkľa
rupuno
rupuno
Rusiko
ruš
rušel
rušto
ruv
sa
sa
sajekh
sajinel
sako
sano
saňarel pes (avri)
saňol (avri)
sap
sapuňalo
sapuňis
sar
sar
sar te
sas
sasto
sastro
sasťarel (pes)
sasťarel (pes) avri
sasťipen
sasťol
sasvi
savo
savoredženengero
savoro
savoro
se
sem
septembris
sera
sersamos
servika
serviko
Serviňa
Servos
servus
sevľi
siďarel
sig
sikra
sikh
sikhado
sikhado avri
sikhavel
sikhavel pes
sikhavel pes avri
sikhaviben
sikhľardo
sikhľardo
sikhľarďi
sikhľarel
sikhľarel pes
sikhľariben
sikhľiben
sikhľipen
sikhľol
silavis
simiris
sirimos
sivel
skamin
skamind
sklepa
skušinel
slabo
slovačika
Slovačiko
slovačiko
Slovakos
Slovenka
Slovensko
slovensko
slugadža
slugadžiko
slugadžipen
slugadžis
smirom
smutno
smutnones
so
soča
sogalinel
sokamel
sokašis
solach
solachardo
solacharel
solacharel pes
somaris
sombat
somnakaj
somnakunes
somnakuno
soske
sovel
soviben
sovľarel
sovnakunes
sovnakuno
spokojno
staďi
starčinel
stolkos
stradel
streda
strično phen
strično phral
stromos
sthovel
sumeňis
sungalo
suno
sunto
suv
svato
sveteris
svetos
svetoskero
svirind
šaj
šajipen
šargo
šarkaňis
šarlagos
ščiro
šegetinel
šegitinel
šel
šelberš
šelo
šeral
šerengero
šernango
šero
šesterňica
šifoňeris
šigitinel
šikovno
šil
šil
šila
šilalo
šilaľarel
šiľaľol
šiľarďi
šing
šinguno
šinguňiko
šinovcos
šišitno
šivo
škola
školakero
šlajeris
šlajiris
šľapetkaris
šľipra
šmakinel
šmakos
šmerca
šmikňa
šoha
šol
šoľarel
šošoj
šougoris
šougorkiňa
šov
šparga
šparheta
špecijalistas
špekulantos
špekuľinel
špitaľa
špringľa
štar
štarešingengero
štarto
štarval
štatos
štatoskero
štokos
štvartkos
Šťefanos
Šťefkus
šudrarel
šudro
šufľa
šuk
šukar
šukares
šukariben
šuko
šulaďi
šulavel
šuľarel
šunďipen
šunďol
šunel
šunel pes
šustros
šut
šutlo
šutľardo
šuťarel
šuťarel avri
šuťol
šuťol avri
šuvlo
šuvľipen
šuvľol
švabka
švablikos
ta
ta
tabla
taboris
tado
taďol
tajsa
takoj
talam
Talijanka
Talijanos
Talijansko
talijansko
talovos
talpa
taľiga
taľinel
taňiris
tarlos
tasado
tasavel
tasľarel
taška
tašľarel
tašľol
tates
tato
tato
taťarel
taťol
tavel
te
te
tebor
te ča
tejos
tel
telal
tele
telefoňinel
telegramos
telethodo
televiza
tepša
terďol
terno
terno
ternovarba(s)tar
ternovarba(ste)
terňi
teštos
tinta
tintakero
tirinel
tiro
tiš
tiviškos
topanka
tosara
tosarutno
tover
tradel
trafinel
tranda
tranzistorovo
trapinel pes
trapišagos
trast
trastuno
trestos
trin
trito
trival
trjeda
tromal
truš
trušalo
tu
tumaro
tumen
tuňarel
tuňo
thagar
thagarikano
thagariko
thagarinel
thagaripen
thagarňi
than
tharďi mol
thav
th'avka
the
them
thodo
thodo avri
thovavel
thovel
thovel pes
thovel pes
thud
thulo
thulo
thuľi goj
thuľol
thuv
thuvalo
thuvalo
thuvľarel
u
u
u aver
u avrether
učeder
učes
učiben
učiťeľis
učiťeľka
učo
učhaj
učhal
učhaľin
učhardo
učhardo
učharďi
učharďol
učharel (andre)
udud
udzimen
ufinel
ufnosťa
uchanel (pes)
ujcus
ulado
ulaďol
ulavel (pes)
uľol
umblaďi
umblal
umblav
umblavel
umblavel pes
ungrika
Ungriko
ungriko
Ungros
upral
upral
urňinel
urňol
ušanel
uštarkerel
uštavel (opre/upre)
ušťel (opre/upre)
uva
uzulankos
užardo
užarel
užariben
vačan
vaďos
vacht
vaj
vaj
vajinel
vajinel andre
vajinel avri
vajkaj
vajkana
vajkeci
vajsar
vakerdo
vakerďi
vakerel
vakeriben
vakeribnaskero
vakerkerel
vakerkeriben
vakerutno
vakinel
vakovka
valceris
valka
valos
valošno
vaľkinel
vandrakos
vapnos
vare-
varekaj
varekana
vareko
varekhaj
varesar
vareso
varinel
varta
vartašis
vartinel
vartinel avri
vartišagos
vasoske
vast
vastaľi
vastora
vastuňi
vaš
vašalinel
vašalos
vašbaba
vaše
vašoda
vatinel
vatinel avri
vatra
vatrakero
vaza
vazdel (opre/upre)
veca
vecos
vera
veraduno
verdan
verdanengere Roma
verdoro
verchos
vesekedinel pes
vesekedišagos
vestinel love (andro karti)
veš
vešalo
veščuchos
vešelo
vešengero
vešengero
vešeskero
vešeskero
vešoro
vešuno
vešuňi džukľi
vešutňipen
veža
vibito
vičinel
vičinel
vičinel avri
vičinel pes
vičinkerel
vičiňiben
vičišagos
vidľa
vidno
vidňisaľol
viďazinel
viganos
vigňa
vigos
vicherno
vichodňaris
vichodňarka
vichodňarsko
vijavinel pes
vika
viksinel
vilagos
vilahoda
vilaňis
Viľija
viľinel
vinkľasto
vinkľos
vinovato
vinšinel
viňička
viparis
viplejimen
viragos
viraguno
virginel
visa
visaľol
visaľol avri
visardes
visardo
visardo avri
visarel
visarel
visarel avri
visarel opre
visarel pale
visarel pes
visarel pes andre
visarel pes avri
visariben
visarkeriben
viš
višalo
vitejziko
vitejzis
vizitka
vjazka
vlachika
vlachiko
Vlachiňa
Vlachos
vlastno
voďalo
voďaľipen
voďeskero
voďi
voďipen
vojna
volinel
voľa
vonatos
vrasarel
všadzik
všelijako
vudar
vudud
vušt
zabava
zabešto
zacato
zadživel
zajda
zakerel
zamakhel
zamamuňinel
zaphendo
zaphendo
zaphenďipen
zaphenel
zarodel (love)
zarodľipen
zasido
zasivel
zasovel
zasuto
zaujma
zbačinel
zbatorinel pes (opre)
zbojňikos
zdrojos
zdžal pes
zegos
zeleno
zgvalcinel
zijand
znameňinel
zor
zoralejileskero
zorales
zoralešereskero
zoralevoďeskero
zoralo
zoraľardo avri
zoraľarel
zoraľarel avri
zoraľipen
zoraľol
zoripen
zorisaľol
zrada
zumado
zumavel
zumavel avri
zumin
zuňiben
zuňimen
zuňinel (pes)
zuňito
žaborina
žadinel
žadno
žajinel
žalostno
žalostnones
žaľa
žaľostno
žaľostnones
žamba
žambakero
žandaris
žara
žeba
žebakero
žebkendos
žeľeno
žičinel (peske)
žirafa
žiromen
žiros
žirošno
živaňis
žobrakos
žobrinel
žuvačka
žuvinel
žužardo
žužardo avri
žužarel
žužarel avri
žužariben
žužarkerel
žužarňi
žužejileskero
žužerateskero
žužes
žužipen
žužo
žužodživ
žužol avri
žvirina
`