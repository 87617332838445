// Define enums for tense mapping keys and descriptions
enum Tense {
    pres = "Presentum",
    fut = "Futurum",
    impf = "Imperfektum",
    perf = "Perfektum",
    condPres = "Konditionális prezens",
    condPerf = "Konditionális perfektum",
    imper = "Imperativus"
}

// Define the structure for the endings dictionary
type Endings = {
    sg: string; // Singular form
    pl: string; // Plural form
};

type Person = {
    1: Endings; // 1st person
    2: Endings; // 2nd person
    3: Endings; // 3rd person
};

type Tenses = {
    pres: Person;
    fut: Person;
    impf: Person;
    perf: Person;    
};

type VerbGroup = {
    1: Tenses;
    2: Tenses;
    3: Tenses;
};

// Define the dictionary structure
const endingsDict: VerbGroup = {
    1: {
        pres: {
            1: { sg: "av", pl: "as" },
            2: { sg: "as", pl: "an" },
            3: { sg: "al", pl: "an" },
        },
        fut: {
            1: { sg: "ava", pl: "aha" },
            2: { sg: "aha", pl: "ana" },
            3: { sg: "ala", pl: "ana" },
        },
        impf: {
            1: { sg: "avas", pl: "ahas" },
            2: { sg: "ahas", pl: "anas" },
            3: { sg: "alas", pl: "anas" },
        },
        perf: {
            1: { sg: "om", pl: "am" },
            2: { sg: "al", pl: "an" },
            3: { sg: "as", pl: "e" },
        },
    },
    2: {
        pres: {
            1: { sg: "av", pl: "as" },
            2: { sg: "es", pl: "en" },
            3: { sg: "el", pl: "en" },
        },
        fut: {
            1: { sg: "ava", pl: "aha" },
            2: { sg: "eha", pl: "ena" },
            3: { sg: "ela", pl: "ena" },
        },
        impf: {
            1: { sg: "avas", pl: "ahas" },
            2: { sg: "ehas", pl: "enas" },
            3: { sg: "elas", pl: "enas" },
        },
        perf: {
            1: { sg: "om", pl: "am" },
            2: { sg: "al", pl: "an" },
            3: { sg: "as", pl: "e" },
        },
    },
    3: {
        pres: {
            1: { sg: "uvav", pl: "uvas" },
            2: { sg: "os", pl: "on" },
            3: { sg: "ol", pl: "on" },
        },
        fut: {
            1: { sg: "uvava", pl: "uvaha" },
            2: { sg: "oha", pl: "ona" },
            3: { sg: "ola", pl: "ona" },
        },
        impf: {
            1: { sg: "uvavas", pl: "uvahas" },
            2: { sg: "ohas", pl: "onas" },
            3: { sg: "olas", pl: "onas" },
        },
        perf: {
            1: { sg: "om", pl: "am" },
            2: { sg: "al", pl: "an" },
            3: { sg: "as", pl: "e" },
        },
    },
};

type TenseEndings = Record<string, Tense>
// Map of tense descriptions
const tenseMapping:  TenseEndings = {
    pres: Tense.pres,
    fut: Tense.fut,
    impf: Tense.impf,
    perf: Tense.perf,
    cond_pres: Tense.condPres,
    cond_perf: Tense.condPerf,
    imper: Tense.imper,
};

export { endingsDict, tenseMapping, Tenses};
