import { SearchOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, FormControlLabel, InputAdornment, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, styled, TextField, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useCallback, useState } from 'react'
import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Gender, generateNounParadigms } from '../paradigm-generator/nouns';
import { processVerb } from '../paradigm-generator/verbs';
import { lemmaSigns } from '../paradigm-generator/lemma-signs';

const ParadigmGenerator = () => {	
	const [ searchQuery, setSearchQuery ] = useState('');
	const [ selectedPOS, setSelectedPOS ] = useState('noun');		
	const [ selectedGender, setSelectedGender ] = useState('male');		
	const [ selectedAnimacy, setSelectAnimacy ] = useState('true');		
	const [ nounData, setNounData ] = useState<{ singular: { [key: string]: string }, plural: { [key: string]: string } } | null>(null);
	const [ verbData, setVerbData ] = useState<{ [key: string]: { [key: string]: { singular: string, plural: string } } } | null>(null);

	const handleSearchChange = useCallback(
		(e) => setSearchQuery(e.target.value),
		[]
	);

	const handleSearchSubmit = useCallback(() => {
		let isValidWord = false;
		
		const signs = lemmaSigns.split('\n');
		isValidWord = signs.some(sign => sign === searchQuery);

		if (isValidWord) {
			selectedPOS === 'noun' && setNounData(
				generateNounParadigms(
					searchQuery,
					selectedGender === 'male' ? Gender.masculine : Gender.feminine,
					selectedAnimacy === 'true' ? true : false
				));

			selectedPOS === 'verb' && setVerbData(processVerb(searchQuery) || null);
		}
	}, [searchQuery, selectedAnimacy, selectedGender, selectedPOS]);

	const handleFieldKeyPress = useCallback(
		(ev) => {
			if (ev.key === 'Enter') {
				handleSearchSubmit();
			}
		},
		[handleSearchSubmit]
	);

	const handlePOSChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedPOS((event.target as HTMLInputElement).value);			
		setNounData(null);	
		setVerbData(null);
		setSearchQuery('');
	};

	const handleGenderChange = (event: SelectChangeEvent<string>) => {
		setSelectedGender((event.target as HTMLInputElement).value);				
	};

	const handleAnimacyChange = (event: SelectChangeEvent<string>) => {
		setSelectAnimacy((event.target as HTMLInputElement).value);				
	};

	const StyledTableRow = styled(TableRow)(({ theme }) => ({		
		'&:nth-of-type(odd)': {
		  backgroundColor: '#E6F0FA',
		},
		// hide last border
		'&:last-child td, &:last-child th': {
		  border: 0,
		},
	  }));

	return (
		<>
			<h2>Generátor paradigiem</h2>
			<div style={{ marginBottom: '10px' }}>      
				<Accordion>
					<AccordionSummary
					expandIcon={<ArrowDropDownIcon />}
					aria-controls="about-rpg-content"
					id="about-rpg-header"
					>
					<Typography component="span">O RPG (Romani Paradigm Generator)</Typography>
					</AccordionSummary>
					<AccordionDetails>
					<Typography>
						RPG (Romani Paradigm Generator) je inovatívny softvér určený na automatickú generáciu nominálnych a slovesných paradigiem v rómčine. Tento nástroj bol 
						vyvinutý s cieľom uľahčiť lingvistické analýzy a výučbu rómskej gramatiky prostredníctvom presného a systematického tvorenia tvarov slovných kategórií.
						Hlavné vlastnosti:
						<ul>
							<li>Slovesné paradigmy: Generovanie všetkých tvarov slovies v rôznych časoch, spôsoboch a osobách podľa štandardizovaných pravidiel.</li>
							<li>Nominálne paradigmy: Automatické skloňovanie podstatných mien podľa pádových a rodových kategórií.</li>
							<li>Používateľsky prívetivé rozhranie: Jednoduchý a efektívny spôsob zadávania údajov a generovania paradigiem.</li>
						</ul>						
						Program RPG predstavuje nástroj pre lingvistov, učiteľov aj študentov rómčiny, ktorí chcú získať detailný prehľad o gramatických štruktúrach tohto jazyka.
						<br></br>
						<br></br>
						Vývoj generátora paradigiem: Michal Husťák, Martin Gális, Dávid Merényi
					</Typography>
					</AccordionDetails>
				</Accordion>
    		</div>
			<div>
				<FormControl style={{ marginBottom: '10px' }}>
					<RadioGroup
						row
						aria-labelledby="pso-radio-buttons-group-label"
						defaultValue={selectedPOS}
						name="pos-radio-buttons-group"
						onChange={handlePOSChange}
					>
						<FormControlLabel
							value="noun"
							control={<Radio size="small" />}
							label="Podstatné meno"
						/>
						<FormControlLabel
							value="verb"
							control={<Radio size="small" />}
							label="Sloveso"
						/>
					</RadioGroup>
				</FormControl>
			</div>
			{selectedPOS === 'noun' && (
				<div>
					<FormControl sx={{ mr: 1, mb: 2, minWidth: 120 }} size='small'>				
						<Select
							labelId="gender-select-label"
							id="gender-select"
							value={selectedGender}					
							onChange={handleGenderChange}
						>
							<MenuItem value={'male'}>Mužský rod</MenuItem>
							<MenuItem value={'female'}>Ženský rod</MenuItem>    
						</Select>
					</FormControl>
					<FormControl sx={{ minWidth: 120 }} size='small'>				
						<Select
							labelId="animacy-select-label"
							id="animacy-select"
							value={selectedAnimacy}					
							onChange={handleAnimacyChange}
						>
							<MenuItem value={'true'}>Životné</MenuItem>
							<MenuItem value={'false'}>Neživotné</MenuItem>    
						</Select>
					</FormControl>
				</div>
			)}
			<TextField
				style={{ marginBottom: '10px' }}
				className="search"
				id="search-input"
				label="Vyhľadajte výraz"
				variant="standard"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchOutlined />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="end">
							<Button
								variant="text"
								className="search-btn"
								onClick={handleSearchSubmit}
								type="submit"
							>
								Vyhľadať
							</Button>
						</InputAdornment>
					),
				}}
				value={searchQuery}
				onChange={handleSearchChange}
				onKeyDown={handleFieldKeyPress}
			/>
			{nounData && <TableContainer component={Paper} sx={{ mt: 2 }}>
				<Table size="small" aria-label="paradigm table">
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell><strong>Singular</strong></TableCell>
							<TableCell><strong>Plural</strong></TableCell>							
						</TableRow>
					</TableHead>
					<TableBody>
						{nounData && Object.keys(nounData.singular).map((key) => (
							<StyledTableRow  key={key}>
								<TableCell><strong>{key}</strong></TableCell>
								<TableCell>{nounData.singular[key]}</TableCell>
								<TableCell>{nounData.plural[key]}</TableCell>
							</StyledTableRow >
						))}
					</TableBody>
				</Table>
			</TableContainer>
		}		
		{verbData && <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
			<Table size="small" aria-label="verb paradigm table">
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell><strong>Singular</strong></TableCell>
						<TableCell><strong>Plural</strong></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.keys(verbData).map((tense: string) => (
						Object.keys(verbData[tense]).map((person: string) => (
							<StyledTableRow key={`${tense}-${person}`}>
								<TableCell><strong>{`${tense} | ${person}. osoba`}</strong></TableCell>
								<TableCell>{verbData[tense][person].singular}</TableCell>
								<TableCell>{verbData[tense][person].plural}</TableCell>
							</StyledTableRow>
						))
					))}
				</TableBody>
			</Table>
		</TableContainer>}
		
		</>
	);
}

export default ParadigmGenerator
