const { endingsDict, tenseMapping, Tenses } = require('./verbsEndings');

function replaceSpecialCharacters(word: string) {
    const translationMap = {
        'č': 'c', 'ť': 't', 'ľ': 'l', 'š': 's', 'ž': 'z',
        'ý': 'y', 'á': 'a', 'í': 'i', 'é': 'e', 'ó': 'o',
        'ú': 'u', 'ŕ': 'r', 'ä': 'a', 'ô': 'o', 'ĺ': 'l',
        'ň': 'n', 'ď': 'd'
    };
    const specialChars = "čťľšžýáíéóúŕäôĺňď";    

    return word.split('').map(char => specialChars.includes(char) ? translationMap[char as keyof typeof translationMap] : char).join('');}

/**
 * Check if the input string contains a word ending with "l".
 * 
 * @param inputString - The string to check.
 * @returns True if the input contains a valid verb ending with "l", otherwise false.
 */
function containsValidVerb(inputString: string): boolean {
    const regex = /\b\w+l\b/;
    return regex.test(inputString);
}

/**
 * Check if the input string is a single word ending with "l".
 * 
 * @param inputString - The string to check.
 * @returns True if the input is a single word ending with "l", otherwise false.
 */
function isValidVerb(inputString: string): boolean {
    const regex = /^.+l$/;
    return regex.test(inputString);
}

function determineConjugationClass(word: string): 1 | 2 | 3 {
    /**
     * Determine conjugation class of a verb based on its ending.
     */

    const endingsToConjClass: { [key: string]: 1 | 2 | 3 } = { 
        "al": 1, 
        "el": 2, 
        "ol": 3 
    };

    for (const [ending, conjClass] of Object.entries(endingsToConjClass)) {
        if (word.endsWith(ending)) {
            return conjClass;
        }
    }

    // Use class 3 as a fallback
    return 3;
}

function definePresRoot(word: string, conjClass: number): string {
    /**
     * Define the present tense root of a verb based on its conjugation class.
     */
    if (conjClass === 1) {
        return word.slice(0, -1); // Remove the last character
    } else {
        return word.slice(0, -2); // Remove the last two characters
    }
}

function definePerfRoot(presRoot: string, conjClass: number): string {
    /**
     * Define the perfect tense root of a verb based on its present root and conjugation class.
     */
    if (conjClass === 1) {
        if (presRoot.endsWith("a")) {
            return presRoot + "nď";
        }
    } else if (conjClass === 2) {
        const lastChar = presRoot[presRoot.length - 1];
        if ("lnrv".includes(lastChar)) {
            return presRoot + "ď";
        } else if ("čgjkhm".includes(lastChar)) {
            return presRoot + "ľ";
        } else if (lastChar === "d") {
            return presRoot + "ň";
        } else if ("sš".includes(lastChar)) {
            return presRoot + "ť";
        } else if (lastChar === "ť") {
            return presRoot + "iľ";
        } else {
            return "xxx";
        }
    }
    
    return presRoot + "iľ";    
}

function findWordEndingWithL(inputString: string): string | null {
    /**
     * Find the first word ending with "l" in a given input string.
     * If the word is surrounded by parentheses, remove them.
     */
    const words = inputString.split(/\s+/); // Split string into words by whitespace

    for (const word of words) {
        if (word.endsWith("l")) {
            return word.replace(/[()]/g, ""); // Remove parentheses if present
        }
    }

    return null; // Return null if no word ending with "l" is found
}

function isConditional(tense: string): boolean {
    /**
     * Check if the given tense starts with 'cond_'.
     */
    return tense.startsWith('cond_');
}

// Here TenseType differ from Tenses in verbEndings.ts (cond_pres and cond_perf are not separated) so we need to adjust the type
type NumberType = 'sg' | 'pl';
type PersonType = 1 | 2 | 3;
type TenseType = 'pres' | 'fut' | 'impf' | 'perf' | 'cond_pres' | 'cond_perf' | 'imper';
type VerbParadigm = Record<TenseType, Record<PersonType, Record<NumberType, string>>>;

function generateVerbParadigms(verb: string): VerbParadigm {
    const conjClass: 1 | 2 | 3 = determineConjugationClass(verb);
    const presRoot = definePresRoot(verb, conjClass);
    const perfRoot = definePerfRoot(presRoot, conjClass);
    
    const tenses: TenseType[] = ['pres', 'fut', 'impf', 'perf', 'cond_pres', 'cond_perf'];
    const persons: PersonType[] = [1, 2, 3];
    const numbers: NumberType[] = ['sg', 'pl'];
    const forms: TenseType[] = ['imper'];
    
    const paradigms: VerbParadigm = { 
        'pres': { 1: { sg: '', pl: '' }, 2: { sg: '', pl: '' }, 3: { sg: '', pl: '' } }, 
        'fut': { 1: { sg: '', pl: '' }, 2: { sg: '', pl: '' }, 3: { sg: '', pl: '' } }, 
        'impf': { 1: { sg: '', pl: '' }, 2: { sg: '', pl: '' }, 3: { sg: '', pl: '' } }, 
        'perf': { 1: { sg: '', pl: '' }, 2: { sg: '', pl: '' }, 3: { sg: '', pl: '' } }, 
        'cond_pres': { 1: { sg: '', pl: '' }, 2: { sg: '', pl: '' }, 3: { sg: '', pl: '' } }, 
        'cond_perf': { 1: { sg: '', pl: '' }, 2: { sg: '', pl: '' }, 3: { sg: '', pl: '' } },         
        'imper': { 1: { sg: '', pl: '' }, 2: { sg: '', pl: '' }, 3: { sg: '', pl: '' } },         
    };
    
    for (const tense of tenses) {        
        let baseTense = '';
        
        for (const person of persons) {            
            if (!paradigms[tense]) {
                paradigms[tense] = { 1: { sg: '', pl: '' }, 2: { sg: '', pl: '' }, 3: { sg: '', pl: '' } };
            }            
            
            for (const number of numbers) {
                let ending = '';
                
                if (isConditional(tense)) {
                    baseTense = tense.split('_')[1];
                    
                    if (tense === 'cond_pres') baseTense = 'impf';
                    
                    try {
                        ending = endingsDict[conjClass][baseTense as typeof Tenses][person][number];
                        
                        if (tense === 'cond_perf') {
                            if (person === 3) {
                                ending = number === 'sg' ? ending.slice(0, -1) + 'h' + 'as' : ending + 'h' + 'as';
                            } else {
                                ending += 'as';
                            }
                        }
                    } catch (e) {
                        ending = '';
                    }
                } else {
                    ending = endingsDict[conjClass][tense as typeof Tenses][person][number];
                }

                let paradigm = '';
                if ((['pres', 'fut', 'impf'].includes(tense) || baseTense === 'impf') && conjClass === 1) {
                    paradigm = `${presRoot.slice(0, -1)}${ending}`;
                } else if (['perf', 'cond_perf'].includes(tense)) {
                    if (person === 3 && number === 'pl') {
                        const modPerfRoot = perfRoot.slice(0, -1) + replaceSpecialCharacters(perfRoot.slice(-1));
                        paradigm = `${modPerfRoot}${ending}`;
                    } else {
                        paradigm = `${perfRoot}${ending}`;
                    }
                } else {
                    paradigm = `${presRoot}${ending}`;
                }
                                
                paradigms[tense][person][number] = paradigm;
            }
        }
    }
    
    for (const form of forms) {
        paradigms[form] = {} as any;
        paradigms[form][1] = {} as any;
        paradigms[form][2] = {} as any;

        for (const number of numbers) {
            let ending = '';
            
            if (number === 'sg') {
                if (conjClass === 3) {
                    ending = 'uv!';
                } else if (conjClass === 2) {
                    if (verb.endsWith('del')) {
                        ending = 'e!';
                    } else if (['chuťel', 'ušťel', 'urel'].includes(verb)) {
                        ending = 'i!';
                    } else {
                        ending = '!';
                    }
                } else {
                    ending = '!';
                }
            } else {
                ending = conjClass === 1 ? 'n!' : conjClass === 2 ? 'en!' : 'on!';
            }
          
            paradigms[form][1]['pl'] = `${paradigms['pres'][1]['pl']}!`;
            paradigms[form][2][number] = `${presRoot}${ending}`;
        }
    }
    
    return paradigms as VerbParadigm;
}

function formatVerbParadigms(verbParadigms: VerbParadigm): string {
    let formattedOutput = "";

    // Get all singular forms for length calculation
    const allSingularForms = Object.values(verbParadigms)
        .flatMap(tenseValue => Object.values(tenseValue).map(forms => forms.sg || ""));
    const maxSingularLength = Math.max(...allSingularForms.map(singularForm => singularForm.length));

    for (const [tenseKey, tenseValue] of Object.entries(verbParadigms)) {
        const formattedTenseName = tenseMapping[tenseKey] || tenseKey;
        formattedOutput += `${formattedTenseName}\n`;

        // Get max plural length
        const maxPluralLength = Math.max(...Object.values(tenseValue).map(forms => (forms.pl || "").length));

        // Indentation header
        const indentation = "    Singulár".padEnd(maxSingularLength + 6) + "Plurál\n";
        formattedOutput += indentation;

        for (const [person, forms] of Object.entries(tenseValue)) {
            const personStr = `${person}.`;
            const singularForm = (forms.sg || "").padEnd(maxSingularLength);
            const pluralForm = (forms.pl || "").padEnd(maxPluralLength);

            formattedOutput += `${personStr.padEnd(3)} ${singularForm} \t${pluralForm}\n`;
        }
        formattedOutput += "\n";
    }

    return formattedOutput;
}

function formatVerbParadigms2(verbParadigms: VerbParadigm): Record<string, any> {
    const formattedOutput: Record<string, any> = {};

    for (const [tenseKey, tenseValue] of Object.entries(verbParadigms)) {
        const formattedTenseName = tenseMapping[tenseKey] || tenseKey;
        formattedOutput[formattedTenseName] = {};

        for (const [person, forms] of Object.entries(tenseValue)) {
            formattedOutput[formattedTenseName][person] = {
                singular: forms.sg || "",
                plural: forms.pl || ""
            };
        }
    }

    return formattedOutput;
}

function containsStandalonePes(inputString: string): boolean {
    // Use regular expression to find 'pes' as a standalone word
    const pattern = /\bpes\b/;
    return pattern.test(inputString);
}

function replacePesForms(paradigms: VerbParadigm): VerbParadigm {
    const pesForms: Record<number, { sg: string; pl: string }> = {
        1: { sg: "man", pl: "amen" },
        2: { sg: "tut", pl: "tumen" },
        3: { sg: "pes", pl: "pen" }
    };

    for (const tense in paradigms) {
        for (const person in paradigms[tense as TenseType]) {
            for (const number in paradigms[tense as TenseType][Number(person) as PersonType]) {
                const pesString = paradigms[tense as TenseType][Number(person) as PersonType][number as NumberType];
                
                // Replace standalone "pes" with the corresponding form
                const updatedString = pesString.replace(/\bpes\b/g, () => pesForms[Number(person)][number as NumberType]);
                
                paradigms[tense as TenseType][Number(person) as PersonType][number as NumberType] = updatedString;
            }
        }
    }

    return paradigms;
}

function replaceNeutralWithForms(
    inputString: string,
    verb: string,
    paradigms: VerbParadigm
): VerbParadigm {
    const modifiedForms: VerbParadigm = {
        pres: {
            1: { sg: '', pl: '' },
            2: { sg: '', pl: '' },
            3: { sg: '', pl: '' }
        },
        fut: {
            1: { sg: '', pl: '' },
            2: { sg: '', pl: '' },
            3: { sg: '', pl: '' }
        },
        impf: {
            1: { sg: '', pl: '' },
            2: { sg: '', pl: '' },
            3: { sg: '', pl: '' }
        },
        perf: {
            1: { sg: '', pl: '' },
            2: { sg: '', pl: '' },
            3: { sg: '', pl: '' }
        },
        cond_pres: {
            1: { sg: '', pl: '' },
            2: { sg: '', pl: '' },
            3: { sg: '', pl: '' }
        },
        cond_perf: {
            1: { sg: '', pl: '' },
            2: { sg: '', pl: '' },
            3: { sg: '', pl: '' }
        },
        imper: {
            1: { sg: '', pl: '' },
            2: { sg: '', pl: '' },
            3: { sg: '', pl: '' }
        }
    };

    for (const tense in paradigms) {
        modifiedForms[tense as TenseType] = { 1: { sg: '', pl: '' }, 2: { sg: '', pl: '' }, 3: { sg: '', pl: '' } };

        for (const person in paradigms[tense as TenseType]) {
            const personKey = Number(person);
            modifiedForms[tense as TenseType][personKey as PersonType] = { sg: '', pl: '' };

            for (const number in paradigms[tense as TenseType][personKey as PersonType]) {
                const paradigm = paradigms[tense as TenseType][personKey as PersonType][number as NumberType];
                
                // Replace verb with the correct form
                const modifiedString =
                    tense === "imper"
                        ? inputString.replace(verb, paradigm.slice(0, -1)) + "!"
                        : inputString.replace(verb, paradigm);

                modifiedForms[tense as TenseType][personKey as PersonType][number as NumberType] = modifiedString;
            }
        }
    }

    return modifiedForms;
}

export function processVerb(inputString: string): Record<string, any> | undefined {
    /**
     * Function that integrates all helper functions to generate paradigms for inputString.
     */
    if (isValidVerb(inputString)) {
        const paradigms = generateVerbParadigms(inputString);
        return formatVerbParadigms2(paradigms);
    } else if (containsValidVerb(inputString)) {
        const verb = findWordEndingWithL(inputString);
        if (verb) {
            let paradigms = generateVerbParadigms(verb);
            paradigms = replaceNeutralWithForms(inputString, verb, paradigms);
    
            if (containsStandalonePes(inputString)) {
                paradigms = replacePesForms(paradigms);
            }
    
            return formatVerbParadigms2(paradigms);
        }
        
    }

    return undefined;
}

// Example usage
const inputString = "terďol";
const result = processVerb(inputString);
console.log(JSON.stringify(result));